import { render, staticRenderFns } from "./BasicCard.vue?vue&type=template&id=5c43a518&scoped=true&"
import script from "./BasicCard.vue?vue&type=script&lang=js&"
export * from "./BasicCard.vue?vue&type=script&lang=js&"
import style0 from "./BasicCard.vue?vue&type=style&index=0&id=5c43a518&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c43a518",
  null
  
)

export default component.exports