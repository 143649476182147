<template>
  <router-link
  :class="['card', desktopAuto ? 'card-desktop' : '']"
  :to="item.link ? item.link : `#!`">
    <div class="card-image">
      <img :src="item.image" :alt="item.name">
    </div>
    <div class="card-title">
      <h5>{{ item.name }}</h5>
      <h6 class="info-text">{{ item.info }}</h6>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'BasicCard',
  props: {
    desktopAuto: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/_mixins.scss';

.card {
  position: relative;
  border: none;
  box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
  border-radius: 15px;
  min-width: 200px;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;

  &-desktop {
    width: 100%;
    @include responsive($scroll-breakpoint) {
      width: 200px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @include responsive($scroll-breakpoint) {
    &:first-child {
      margin-left: 15px;
    }
    &:last-child {
      margin-right: 15px;
    }
  }

  &-image {
    max-width: 100%;
    max-height: 150px;
    overflow: hidden;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;

    img {
      min-width: 100%;
      height: 150px;
      width: auto;
    }
  }

  &-title {
    padding: 1rem 0.5rem;
    margin-bottom: 0;

    h5 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .info-text {
      margin-top: auto;
      font-size: 12px;
      text-transform: uppercase;
      color: $primary;
    }
  }
}
</style>
